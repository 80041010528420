@import '../../styles/variables';

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__map {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    height: 578px;
    background: green;
    border-top-left-radius: $border-lg;
    border-top-right-radius: $border-lg;

    h1 {
      max-width: 200px;
      margin: 24px 0 0 30px;
      font-weight: 700;
      color: #fff;
      font-size: 20px;
    }
  }
  
  &__wrapper {
    position: relative;
    z-index: 2;
    flex-grow: 1;
    margin-top: 170px;
    background: #fff;
    border-top-left-radius: $border-lg;
    border-top-right-radius: $border-lg;
  }

  &__content {
    position: relative;
    padding-top: $gap-xl;
    padding-bottom: $gap-xxl + 40px;
  }

  &__title {
    max-width: 260px;
    margin: 0;
    margin-bottom: $gap-xl;
    font-weight: 700;
    font-size: 20px;
  }

  &__skus-badge {
    position: absolute;
    top: 24px;
    right: 16px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $lightBlue;
    border-radius: 50%;

    span {
      font-weight: 500;
      font-size: 32px;
    }
  }

  &__search-input {
    width: 100%;
    margin-bottom: $gap-xs + 2px;
    padding: 12px 16px;
    padding-left: 48px;
    border-radius: 66px;
    color: $primary;
    background-color: #F2F2F2 !important;
    border: none;
  
    &::placeholder {
      color: $primary;
    }

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba($color: $paleSilver, $alpha: .5)
    }
  }

  &__swiper {
    margin-top: $gap-lg;
  }

  &__skus-helper-alert {
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap-lg;
    margin-top: $gap-xl;
    margin-bottom: $gap-lg;
    padding: $gap-lg + 4px $gap-xl $gap-lg;
    border-radius: $gap-lg + 4px;

    span {
      max-width: 325px;
      text-align: center;
      color: $slateGray;
      line-height: 1.14;
      font-size: $font-size-regular;
    }
  }

  &__sku-list {
    display: flex;
    flex-wrap: wrap;
    gap: $gap-sm;

    .sku-card {
      width: 100%;

      @media (min-width: 360px) {
        flex: 0 1 calc(50% - ($gap-sm / 2));
        width: auto;
      }
    }
  }
}

.floating-block {
  position: fixed;
  z-index: 10;
  bottom: $gap-lg;
  right: $gap-sm + 2px;
  left: $gap-sm + 2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $gap-xs + 2px;
  max-width: 768px;
  margin: 0 auto;

  @media (min-width: 400px) {
    right: $gap-lg;
    left: $gap-lg;
    gap: $gap-sm;
  }

  &__balance-btn {
    @media (max-width: 399px) {
      height: 62px;
      padding: $gap-sm $gap-lg !important;
    }
  }

  &__balance-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__balance-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;

    @media (min-width: 400px) {
      font-size: 20px;
    }
  }

  &__balance-subtitle {
    font-size: 13px;
    color: $green;

    @media (min-width: 400px) {
      font-size: $font-size-regular;
    }
  }
}
