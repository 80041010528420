@import '../../styles/variables';

.custom-btn {
  cursor: pointer;
  height: 72px;
  text-align: center;
  border-radius: $border-xxl;
  line-height: 1.17;
  color: $primary;
  font-size: $font-size-regular;
  font-weight: 500;
  padding: $gap-md $gap-lg + 4px;
  white-space: nowrap;
  transition: background .2s ease-in-out;

  &--primary {
    background: $primary;
    color: $secondary;
  }

  &--secondary {
    background: $paleSilver;
    color: $primary;
  }
}
