@import '../../styles/variables';

.scan-check {
  position: fixed;
  bottom: 1%;
  right: 0;
  left: 0;
  z-index: 10002;
  width: 100%;
  height: 100%;
  max-height: 680px;
  opacity: 1;
  visibility: visible;
  overflow: hidden;

  @media (min-width: 340px) {
    max-width: 320px;
    left: auto;
    right: 2%;
  }

  &__content{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap-sm;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    max-height: 592px;
    padding: $gap-lg;
    padding-bottom: $gap-xl - 2px;
    border-radius: 18px;
    font-weight: 600;
    line-height: 1.2;
    color: $light-black;
    background: #FFF;
    overflow: hidden;

    &--end {
      justify-content: center;
    }
    &--prSm {
      padding-right: 16px;
    }
    &--noPb {
      padding-bottom: 0;
    }
    &--noPr {
      padding-right: $gap-xs;
    }
    &--alignStart {
      justify-content: flex-start;
    }
  }

  &__title-svg {
    margin-right: 6px;
    margin-top: -4px;
  }

  &__loading {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }

  &__footer {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;

    &--end {
      justify-content: flex-end;
    }

    @media (max-width: 339px) {
      padding: 0 5px;
    }

    .success-btn {
      position: relative;
      width: 232px;
      height: 80px;
      border-radius: 16px;
      background: $primary;
      color: #FFF;
      font-size: 20px;
      font-weight: 600;
      border: none;

      &--animated {
        transition: background .2s ease-in-out, transform .3s ease-in-out , opacity .3s ease-in-out !important;
      }

      &--hidden {
        transform: translateX(120px) !important;
        opacity: 0;
        pointer-events: none;
      }

      &--disabled {
        cursor: default;
        background: $gray-200;
        color: $light-gray;

        &:active {
          background: $gray-200 !important;
        }
  
        @media (min-width: 1024px) {
          &:hover {
            background: $gray-200 !important;
          }
        }
      }

    }

    .footer-btn {
      position: relative;
      width: 80px;
      height: 80px;
      border-radius: 49px;
      border: 1px solid #E7E7E7;
      background: #FFF;
      transition: background .2s ease-in-out;
    }

    .play-btn {
      position: absolute;
      right: 0;
      z-index: -1;
      overflow: hidden;
      opacity: 0;
      transform: translateX(80px);
      transition: transform .2s ease-in-out, opacity .2s ease-in-out;


      &-show {
        position: relative;
        z-index: 1;
        overflow: visible;
        opacity: 1;
        transform: none;
      }
    }
  }

  &__helper-alert {
    cursor: default;
    margin-bottom: $gap-xl + 4px;
    padding: $gap-xl $gap-lg - 2px;
    text-align: center;
    font-size: $font-size-large;
    line-height: 1.22;
    letter-spacing: -0.15px;
    font-weight: 700;
    color: $primary;

    &--noMb {
      margin-bottom: 0;
    }

    &--danger {
      color: $danger;
    }
  }

  .back-block {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    margin-bottom: $gap-xl;
    background: $darkWhiteSmoke;
    border-radius: $border-md + 2px;
  }

  .main-title {
    font-size: 24px;
    line-height: 1.16;
    margin-bottom: $gap-xl;

    span {
      color: #B9C66C;
    }

    &--mr {
      margin-right: $gap-md;
    }

    &--pending {
      span {
        color: #FECC4E;
      }
    }
    &--error {
      span {
        color: $danger;
      }
    }
  }

  .helper-title {
    font-size: 24px;
    font-weight: 700;
    color: #B8B8B8;
  }
  .helper-text {
    text-align: left;
    font-weight: 600;
    font-size: $font-size-large;
    color: $light-black;
  }

  .scan-check-btn-container {
    display: flex;
    flex-direction: column;
    gap: $gap-sm - 2px;
  }
}

.scan-select {
  cursor: pointer;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  font-size: 16px;
  color: $primary;
  background: #F5F5F5;
  border-radius: 24px;
  border: none;

  &:focus-visible {
    outline: none;
  }
}


.scan-custom-scroll {
  &::-webkit-scrollbar {
    width: 4px;
  };
  &::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 22px;
  };
  &::-webkit-scrollbar-track {
    margin-bottom: 16px;
    background: transparent;
  };

  &::-webkit-scrollbar {
    height: 4px;
  };
}