@import '../../styles/variables';

.slider-card {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 230px;
  border-radius: $border-md;

  &__bg {
    position: absolute;
    left: 0;
    z-index: 0;
    width: 99%;
    height: 100%;
    object-fit: cover;
    border-radius: $border-md;
  }

  &__body {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 66%;
    padding: $gap-xl $gap-lg;
    background: $lightBlue;
    border-radius: $border-md;
  }
  
  &__text{
    font-size: 22px;
    font-weight: 600;
  }

  &__tag {
    position: absolute;
    top: $gap-lg;
    right: $gap-xl;
    font-size: 13px;
    color: $green;
  }
}

.swiper {
  overflow-y: visible !important;
}

.swiper-pagination {
  position: static;
  margin-top: $gap-md;
}

.swiper-pagination-bullet {
  background: $paleSilver;
  opacity: 1 !important;

  &-active {
    background: $primary !important;
  }
}

