@import '../../../../styles/variables';

.scan-check-faq {

  &::after {
    content: "";
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, #fff);
  }

  &--hideFog {
    &::after {
      background: transparent;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $gap-xxl;
    max-height: calc(504px - $gap-xl);
    overflow-y: auto;
    padding-right: $gap-md;
    padding-bottom: $gap-lg;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: $gap-sm;

    &--inn {
      gap: $gap-xl;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    gap: $gap-sm;
    margin-bottom: $gap-md + 8px;
  }
}