/**
 Main Palette
 */
 $primary: #45483C !default;
 $secondary: #F0FF93 !default;
 $background: #F2F3EA !default;
 $slateGray: #777779 !default;
 $paleSilver: #C4D0B4 !default;
 $lightBlue: #D4E1EA !default;
 $paleGreen: #E0E4BE !default;
 $lightGray: #E7EAD5 !default;
 $whiteSmoke: #F5F5F5 !default;
 $danger: #FF6C3E !default;
 $danger-light: #FFF2E2 !default;
 /**
   Additional Palette
  */
 $green: #7C905C !default;
 $light-green: #ECEDE0 !default;
 $light-blue: #C8DDE1 !default;
 $light-yellow: #F7FFC7 !default;
 $hover-light-bg: #EFEFEF !default;
 $transparentWhite: rgba($color: #ffffff, $alpha: 0.7);
 $gray: #F0F0F0 !default;
 $gray-100: #F1F1F1 !default;
 $gray-200: #E8E9EC !default;
 $gray-300: #DCDCDC !default;
 $gray-400: #F4F4F4 !default;
 $gray-500: #A1A1A1 !default;
 $light-gray: #929292 !default;
 $dark-gray: #2C2C2C !default;
 $light-black: #353535 !default;
 $darkWhiteSmoke: #F6F6F6 !default;
 
 /**
   Borders
  */
 $border-color: #D4D4D4 !default;
 $border-sm: 8px !default;
 $border-md: 16px !default;
 $border-lg: 24px !default;
 $border-xl: 32px !default;
 $border-xxl: 48px !default;
 
 /**
  Spacing
  */
 $gap-xs: 4px;
 $gap-sm: 8px;
 $gap-md: 12px;
 $gap-lg: 16px;
 $gap-xl: 24px;
 $gap-xxl: 32px;
 
 /**
  Typography
  */
 $font-size-huge: 1.875rem; // 30px
 $font-size-large: 1.125rem; // 18px
 $font-size-semi-large: 1rem; // 16px
 $font-size-regular: 0.875rem; // 14px
 $font-size-small: 0.75rem; // 12px
 
 $font-family-roboto: 'Roboto', Arial, sans-serif;
 
 // effects
 $primary-hover: #6A6E5D;
 $secondary-hover: #DDE992;
 $white-hover: #E8E9EC;
 $olive-hover: #CBCFA5;
 $light-olive-hover: #DDE1C5;
 $light-brown-hover: #CBCFA5;
 $green-hover: #A1AD92;
 $light-yellow-hover: #E5EEAF;
 $light-blue-hover: #ADCFD6;
 