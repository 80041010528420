@import '../../../../styles/variables';

.new-device-qr {

  &__title {
    margin-bottom: $gap-sm + 4px !important;
  }

  &__qr-wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
    }
  }

  &__alert {
    margin-bottom: 0 !important;
    margin-top: $gap-sm + 2px;
  }
}