@import '../../styles/variables';

.scan-check-helperBtn {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 54px;
  padding: 10px $gap-xl;
  border: none;
  font-weight: 600;
  border-radius: $border-md;
  font-size: $font-size-semi-large;

  &--primary {
    background: $darkWhiteSmoke;
    color: $primary;
  }
  &--secondary {
    background: $primary;
    color: #fff;
  }
  &--bordered {
    background: #fff;
    color: $primary;
    border: 1px solid $primary;
  }
  &--disabled {
    cursor: default;
    background: $gray-200;
    color: $light-gray;
  }

  &__number-alert {
    position: absolute;
    right: 1.75 * $gap-xl;
    top: 51%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $gap-xs - 1px $gap-md;
    border-radius: 32px;
    background: $paleGreen;
    font-size: $font-size-regular;
    line-height: 1.28;
    color: $primary;
    font-weight: 700;
    transform: translateY(-50%);

    &--negative {
      background: $danger-light;
    }

    &--noArrow {
      right: $gap-lg;
    }
  }

  &__indicator {
    position: absolute;
    right: 1.9 * $gap-xl;
    top: 51%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #FFC737;

    &--success {
      background: $primary;
    }
    &--error {
      background: $danger;
    }
  }


  &__icon {
    position: absolute;
    right: $gap-xl;
    top: 54%;
    transform: translateY(-50%);
  }

}