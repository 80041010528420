@import '../../../../styles/variables';

.scanMethods {
  padding-right: $gap-md;
  overflow-y: auto;

  &__btn-container {
    display: flex;
    flex-direction: column;
    gap: $gap-xs + 2px;
    margin-bottom: $gap-xxl;
  }

  &__no-camera {
    margin-bottom: $gap-lg;
    font-weight: 700;
    color: #B8B8B8;
    font-size: 20px;
  }

  .hidden-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__loading {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
}