@import '../../../../styles/variables';

.manual-req-enter {

  &__content {
    .main-title {
      margin-right: $gap-lg;
    }
  }

  &__form {
    max-height: 360px;
    padding-right: $gap-md;
    padding-bottom: $gap-lg;
    overflow-y: auto;
    overflow-x: hidden;

    &--photo {
      max-height: 340px;
    }
  }

  &__ofdHelper {
    margin-top: $gap-xl;
    margin-bottom: $gap-xl;
    text-align: center;
    color: $light-gray;
    font-size: $font-size-regular;

    span {
      cursor: pointer;
      transition: color .2s ease-in-out;

      &:active {
        color: $slateGray;
      }
    
      @media (min-width: 1024px) {
        &:hover {
          color: $slateGray;
        }
      }
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    gap: $gap-xs + 2px;

    .scan-check-helperInput {
      min-height: 48px;
    }
  }
}