@import '../../styles/variables';

.scan-check-helperInput {
  position: relative;
  display: flex;
  align-items: center;
  gap: $gap-xs;
  width: 100%;
  height: 54px;
  padding: 0 $gap-xl;
  border: none;
  font-weight: 600;
  border-radius: $border-md;
  font-size: $font-size-semi-large;
  background: $darkWhiteSmoke;

  &--error {
    background: $danger-light;
  }

  &__label {
    font-weight: 400;
    white-space: nowrap;

    &--disabled {
      color: rgba($color: $primary, $alpha: 0.5);
    }
  }

  &__input {
    all: unset;
    cursor: pointer;
    width: 100%;
    padding: 2px $gap-xs;
    line-height: 1;
    font-weight: 600;
    color: $light-black;
    border: none;
    background: transparent;

    &::placeholder {
      margin-left: -8px;
      color: #B8B8B8;
      font-weight: 600;
    }
  }

  input:disabled {
    cursor: default;
    color: rgba($color: $primary, $alpha: 0.5);
  }

  &__helperLabel {
    white-space: nowrap;
    margin-bottom: 1px;
    color: #B8B8B8;
    font-weight: 600;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
      appearance: none;
      -moz-appearance: textfield;
  }

  input[type="date"], input[type="time"]  {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: $gap-xs - 2px;
  }


  input[type=date]::-webkit-calendar-picker-indicator{
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
  input[type=time]::-webkit-calendar-picker-indicator{
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
  input[type=date]{
    background: url("~/public/images/icons/scan-check/date-icon.svg") 99% center/18px 24px no-repeat !important;
  }
  input[type=time]{
    background: url("~/public/images/icons/scan-check/time-icon.svg") right center/20px 24px no-repeat !important;
  }
}