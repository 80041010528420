@import '../../styles/variables';

.new-select {
  position: relative;
  width: 100%;

  &__select {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding: $gap-md $gap-lg + 4px;
    color: $primary;
    background: $whiteSmoke;
    font-size: $font-size-semi-large;
    font-weight: 400;
    border-radius: $border-lg;

    &--error {
      background: $danger-light;
    }
    &--disable {
      cursor: default;
    }
  }

  &__label {
    margin-bottom: 1px;
    margin-right: $gap-sm;
    font-weight: 700;
    white-space: nowrap;

    &--disabled {
      color: rgba($color: $primary, $alpha: 0.5);
    }
  }

  &__text {
    &--disabled {
      color: rgba($color: $primary, $alpha: 0.5);
    }
  }

  &__icon {
    position: absolute;
    right: $gap-lg + 4px;
    top: 23%;;

    &--rotate {
      transform: rotate(180deg);
    }
  }

  &__option-list {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 105%;
    max-height: 400px;
    border-radius: $border-md;
    background: #fff;
    border: 1px solid $gray-200;
    overflow: hidden;
    overflow-y: auto;
  }

  &__option {
    cursor: pointer;
    width: 100%;
    padding: $gap-md $gap-lg + 4px;
    background: #fff;
    color: $primary;
    font-size: $font-size-semi-large;
    font-weight: 500;
    transition: background .2s ease-in-out;

    &--selected {
      background: $whiteSmoke;
    }

    @media (min-width: 992px) {
      &:hover {
        background: $whiteSmoke;
      }
    }
    &:active {
      background: $whiteSmoke;
    }
  }
}