@import '../../styles/variables';

.pharmacies {
  position: relative;

  &__top {
    margin-bottom: 440px;
  }

  &__title {
    max-width: 260px;
    margin: 0;
    margin-bottom: $gap-xl;
    font-weight: 700;
    font-size: 20px;
  }

  &__skus-helper-alert {
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap-lg;
    margin-top: $gap-xl;
    margin-bottom: $gap-lg;
    padding: $gap-lg + 4px $gap-xl $gap-lg;
    border-radius: $gap-lg + 4px;

    span {
      max-width: 325px;
      text-align: center;
      color: $slateGray;
      line-height: 1.14;
      font-size: $font-size-regular;
    }
  }

  &__pharmacy-list {
    display: flex;
    flex-direction: column;
    gap: $gap-sm - 2px;
    margin-top: $gap-xl;
  }
}