.container {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  // @media (min-width: 992px) {
  //   max-width: 960px;
  // }
}

.no-scroll {
  overflow: hidden;
}

.search-input {
  width: 100%;
  margin-bottom: $gap-xs + 2px;
  padding: 12px 16px;
  padding-left: 48px;
  border-radius: 66px;
  color: $primary;
  background-color: #F2F2F2 !important;
  border: none;

  &::placeholder {
    color: $primary;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba($color: $paleSilver, $alpha: .5)
  }
}

.scan-btn {
  @media (max-width: 399px) {
    height: 62px;
    padding: $gap-sm $gap-lg !important;
  }

  padding-left: $gap-xxl - 2px !important;
  padding-right: $gap-xxl - 2px !important;

  &--fixed {
    position: fixed;
    z-index: 30;
    bottom: $gap-lg;
    left: 50%;
    margin-left: -114px;

    @media (min-width: 400px) {
      margin-left: -123px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: $gap-xs;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 13px;
    color: #A4A4A4;

    @media (min-width: 400px) {
      font-size: $font-size-regular;
    }
  }
}

.dark-bg {
  background: rgba($color: #000000, $alpha: 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 10001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.custom-scroll-X {
  &::-webkit-scrollbar {
    height: 4px;
  };
  &::-webkit-scrollbar-thumb {
    background: #45483C;
    border-radius: 16px;
  };
  &::-webkit-scrollbar-track {
    margin-right: 15px;
    border-radius: 16px;
    background: #E0E4BE;
  };
}

.custom-scroll-Y {
  &::-webkit-scrollbar {
    width: 4px;
  };
  &::-webkit-scrollbar-thumb {
    background: #45483C;
    border-radius: 16px;
  };
  &::-webkit-scrollbar-track {
    margin-bottom: 18px;
    margin-top: 18px;
    background: transparent;
  };
}
