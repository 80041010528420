@import '../../../../styles/variables';

.scan-check-checkInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::after {
    content: "";
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, #fff);
  }

  .scan-check-sku {
    display: flex;
    flex-direction: column;
    gap: $gap-lg;
    max-height: 360px;
    padding-right: $gap-md;
    padding-bottom: $gap-lg;
    overflow-y: auto;
    overflow-x: hidden;

    &__sku-info {
      display: flex;
      flex-direction: column;
      gap: $gap-sm;
      padding: $gap-lg + 2px $gap-lg + 4px;
      border-radius: $border-md;
      background: $whiteSmoke;

      &--success {
        background: #EBEFD2;
      }
    }

    &__bonus-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 54px;
      padding: 0 $gap-lg + 4px;
      border-radius: $border-md;
      background: $whiteSmoke;

      &--success {
        background: #EBEFD2;
      }
    }

    .sku-title {
      font-weight: 600;
      font-size: $font-size-semi-large;
      line-height: 1.125;
      color: $primary;
    }
    .sku-subtitle {
      font-weight: 600;
      font-size: $font-size-semi-large;
      line-height: 1.125;
      color: $light-gray;
    }
  }

}