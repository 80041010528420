.scan-check-loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #DDF0C5;
  padding-bottom: 11px;

  span {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 20px 2px;
    border-radius: 100%;
    background: #6DC26C;
  }

  span:nth-child(1) {
    animation: loadingBounce 1s ease-in-out infinite;
  }

  span:nth-child(2) {
    animation: loadingBounce 1s ease-in-out 0.33s infinite;
  }

  span:nth-child(3) {
    animation: loadingBounce 1s ease-in-out 0.66s infinite;
  }

}
