@import '../../../../styles/variables';

.scanQR-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10002;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  max-height: 100%;
  background: $background;
  overflow: hidden;

  @media (min-width: 768px) {
    max-width: 640px;
    margin: auto;
    max-height: 672px;
    border-radius: $border-lg;
  }

  &__header {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    padding: $gap-md + 2 $gap-lg;
    background: #fff;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  &__title {
    font-weight: 700;
   font-size: $font-size-large;
   color: $primary;
  }

  &__header-close {
    cursor: pointer;
    display: inline-block;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray-200;
    border-radius: 50%;
    transition: .2s background ease-in-out;

    &:hover {
      background: $gray-300;
    }
    &:active {
      background: $gray-300;
    }
  }

  &__video {
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media (min-width: 768px) {
      width: max-content;
      height: max-content;
      transform: translateX(-25%);
    }
  }

  &__scanner {
    width: 100%;
    height: 100vh;
    object-fit: cover;

    @media (min-width: 768px) {
      position: relative;
      inset: 0;
      width: 100% !important;
      height: 100%  !important;
    }
  }

  &__blackout {
    position: absolute;
    z-index: 2;
    inset: 0;
    background: rgba(0, 0, 0, 0.15);
  }

  &__close {
    z-index: 3;
    position: absolute;
    top: $gap-xl;
    left: $gap-xl;

    @media (min-width: 768px) {
      display: none !important;
    }
  }
  &__alert {
    z-index: 3;
    position: absolute;
    left: $gap-lg;
    right: $gap-lg;
    bottom: $gap-xs;
  }
}

.scan-region-highlight {
  z-index: 10 !important;
}
.scan-region-highlight-svg {
  display: none;
}
.code-outline-highlight {
  stroke: #EBFF00 !important;
  stroke-dasharray: 0 !important;
  stroke-width: 4 !important;
}
