@import '../../styles/variables';

.sku-card {
  cursor: pointer;

  &__container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: $background;
    border-radius: $border-md;
  }

  &__image-wrapper {
    padding: 2px;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 186px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: $border-md;

    img {
      width: 90%;
      max-width: 200px;
      height: 120px;
      border-radius: 4px;
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: $gap-sm $gap-md;
  }

  &__title {
    font-size: 15px;
    margin-bottom: $gap-sm;
    font-weight: 700;
  }

  &__manuf {
    margin-bottom: auto;
    font-size: $font-size-regular;
    color: #A4A4A4;
  }

  &__bonus {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__bonus-text {
    font-size: $font-size-regular;
    color: #A4A4A4;
  }

  &__bonus-label {
    padding: $gap-xs - 1px $gap-sm + 3px;
    border-radius: $border-lg + 8px;
    background: #fff;
    white-space: nowrap;

    span {
      font-size: $font-size-regular;
      font-weight: 700;
    }
  }
}
