@import '../../styles/variables';

.pharmacy-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    max-width: 150px;
    text-align: left;
    word-wrap: normal;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.28;
    overflow: hidden;

    @media (min-width: 400px) {
      max-width: 250px;
      font-size: $font-size-regular;
    }

    @media (min-width: 600px) {
      max-width: 350px;
    }
  }
  &__subtitle {
    max-width: 150px;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.28;
    color: $light-gray;

    @media (min-width: 400px) {
      max-width: 100%;
      font-size: $font-size-regular;
    }
  }
}