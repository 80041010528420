@import '../../styles/variables';

.header {
  margin-bottom: $gap-xl;
  padding: 18px 16px;
  background-color: transparent;
  font-size: $font-size-regular;
  color: $primary;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
  }
  
  .left {
    display: flex;
    align-items: center;
  }

  .right {
    display: flex;
    align-items: center;
  }

  .account {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    &:active > & .avatar {
      background: #6A6E5D;
    }

    .notification-badge {
      position: absolute;
      left: -16px;
      top: -8px;
      display: inline-block;
      width: 32px;
      height: 24px;
      padding-top: $gap-sm - 3px;
      text-align: center;
      border-radius: $border-xl;
      background: $paleGreen;
      font-weight: 700;
    }
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: $primary;
    color: #fff;
    text-decoration: none !important;
    font-weight: 700;
    font-size: $font-size-regular;
    letter-spacing: 0.25%;
    transition: background .2s ease-in-out;
  }
}
