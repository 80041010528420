@import '../../styles/variables';

.pharmaciesMap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 504px;
  border-radius: $border-lg;
  overflow: hidden;
}

[class*="copyrights-pane"] {
	display: none !important;
}

[class*="ground-pane"] {
	filter: grayscale(1);
}