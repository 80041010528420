@import '../../styles/variables';

.mybalance {
  position: relative;

  &__top {
    margin-top: $gap-xl;
  
    @media (min-width: 768px) {
      margin-top: 40px;
    }
  
    .left {
      max-width: 282px;

      @media (min-width: 360px) {
        max-width: 306px;
      }
    }

    .right {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 8px;
      grid-row-gap: 6px;

      &__item {
        position: relative;
        padding: $gap-md $gap-md $gap-md + 2 $gap-lg;
      }

      .sm {
        grid-column: span 2;
        padding-top: 44px;
      }
      .md {
        grid-column: span 3;
      }
      .lg {
        grid-column: span 5;
      }

      .title {
        max-width: 80px;
        margin: 0;
        font-weight: 500;
        font-size: 28px;
        line-height: 1.17;
        color: $primary;

        @media (min-width: 360px) {
          font-size: 32px;
        }

        &--sec {
          font-size: $font-size-regular;
          font-weight: 600;
          color: $secondary;

          @media (min-width: 360px) {
            font-size: $font-size-semi-large;
          }
        }
      }

      .subtitle {
        max-width: 156px;
        font-size: 13px;
        color: rgba($color: #373737, $alpha: .7);

        @media (min-width: 360px) {
          font-size: $font-size-regular;
        }
      }
    }
  }

  &__top-loading {
    min-height: 40px;
  }

  &__top-container {
    max-width: 282px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap-md;
    overflow-x: auto;
    margin: 0 auto;
    margin-bottom: $gap-xl;

    @media (min-width: 360px) {
      max-width: 306px;
    }
  }

  &__top-text {
    margin-bottom: $gap-lg;
    text-align: center;
    font-size: $font-size-regular;
    color: rgba($color: #45483C, $alpha: 0.7);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: $gap-sm - 2px;

    .no-content {
      cursor: default;
      padding: 20px 38px;
      text-align: center;
      font-size: $font-size-regular;
      color: #777779;
  
      span {
        display: inline-block;
        max-width: 320px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item-title {
    max-width: 150px;
    text-align: left;
    word-wrap: normal;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.28;
    overflow: hidden;

    @media (min-width: 400px) {
      max-width: 250px;
      font-size: $font-size-regular;
    }

    @media (min-width: 600px) {
      max-width: 350px;
    }

    @media (min-width: 768px) {
      max-width: 450px;
    }
  }

  &__item-subtitle {
    max-width: 150px;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.28;
    color: $light-gray;

    @media (min-width: 400px) {
      max-width: 100%;
      font-size: $font-size-regular;
    }
  }

  &__title {
    margin-bottom: 0;
    color: $primary;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;

    @media (min-width: 992px) {
      font-size: 22px;
    }
  }

  &__alerts-container {
    display: flex;
    align-items: center;
    gap: $gap-md;
    margin-bottom: $gap-xl;

    @media (max-width: 389px) {
      flex-direction: column;
      align-items: baseline;
    }

    .item-alert {
      display: flex;
      align-items: flex-end;
      width: calc(50% - 2px);
      height: 100%;
      padding: 28px 14px 8px 14px;
    
      &:hover {
        text-decoration: none;
      }
    
      &--sm {
        width: calc(32% - 4px);
    
        @media (max-width: 389px) {
          width: 100%;
        }
      }
  
      &--active {
        background: $primary !important;
        color: $secondary !important;
        
        @media (max-width: 389px) {
          width: 100%;
        }
      }
  
      &--active .title {
        color: $secondary !important;
      }
  
      .title {
        max-width: 80px;
        margin-top: $gap-md;
        margin-bottom: $gap-sm;
        font-weight: 600;
        font-size: $font-size-semi-large;
        line-height: 1.17;
        color: $primary;
    
        @media (max-width: 389px) {
          max-width: 80%;
        }
      }
  
    }
  }

  &__alerts-social-container {
    display: flex;
    align-items: center;
    gap: $gap-xs;
    flex: 1 0 66%;
    align-self: stretch;

    @media (max-width: 389px) {
      width: 100%;
      flex: 1 0 100%;
    }
  }
}