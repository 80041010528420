@import './styles/index.scss';

html {
	-webkit-print-color-adjust: exact;
  -webkit-tap-highlight-color: transparent;
}
* {
	box-sizing: border-box;
	-webkit-print-color-adjust: exact;
  margin: 0;
	padding: 0;
  border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.17;
  color: $primary;
}

#root {
  min-height: 100vh;
  min-width: 320px;
  max-width: 768px;
  margin: 0 auto;
  background-color: $background;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

a, a:link, a:visited  {
  text-decoration: none;
}

a:hover  {
  text-decoration: none;
}

input, button {
  font-family: inherit;
  font-size: $font-size-semi-large;

  &::placeholder {
    font-size: $font-size-semi-large;
  }
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}
