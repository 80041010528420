@import '../../../../styles/variables';

.ofg-operatop-helper {

  align-self: flex-start;
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: $gap-sm;
  }

}