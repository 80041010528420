@import '../../styles/index';

.balance {
  font-family: $font-family-roboto;
  border-radius: $border-md;
  padding: $gap-lg - 2 $gap-md - 2 $gap-md $gap-lg - 2;
  background: $primary;
  width: 104px;

  &--large {
    position: relative;
    width: 282px;
    height: 192px;
    padding: $gap-xl $gap-lg $gap-md + 2px $gap-lg;
    border-radius: $border-md + 4px;
    color: $transparentWhite;

    @media (min-width: 360px) {
      width: 306px;
      padding: $gap-xl $gap-xl $gap-md + 2px $gap-xl;
    }

    .item {
      display: grid;
      align-content: space-between;
      height: 100%;
    }

    .title {
      margin-top: $gap-xs;
      font-size: $font-size-huge;
      font-weight: 500;
      color: white;
    }

    .text {
      font-weight: 400;
    }

    .decoration {
      position: absolute;
      right: 18px;
      top: 21px;
      z-index: 0;

      @media (min-width: 360px) {
        right: 24px;
      }
    }

    .top {
      margin-top: 0;
    }

    .bottom {
      position: relative;
      align-items: center;
      border-top: 1px solid hsla(75,4%,78%,.2);
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-size: $font-size-regular;
      background: $primary;
      z-index: 2;
    }

    .bonus {
      display: flex;
      font-weight: 600;
      gap: $gap-xs;
      padding-top: $gap-sm;
    }

    .bottom-left {
      border-right: 1px solid hsla(75,4%,78%,.2);
    }

    .bottom-right {
      padding-left: $gap-md;

      .bonus {
        color: #f0ff93;
      }

      .text {
        white-space: nowrap;
      }
    }
  }
}
