@import '../../styles/variables';

.pharmacyInfo {
  position: relative;

  &__helper-alert {
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap-lg;
    margin-top: $gap-lg;
    margin-bottom: $gap-lg;
    padding: $gap-lg + 4px $gap-xl $gap-lg;
    border-radius: $gap-lg + 4px;

    &--top {
      margin-top: 0;
    }

    span {
      max-width: 325px;
      text-align: center;
      color: $slateGray;
      line-height: 1.14;
      font-size: $font-size-regular;
    }
  }

  &__title {
    max-width: 260px;
    margin-bottom: $gap-lg;
    margin-left: $gap-sm;
    font-weight: 600;
    font-size: $font-size-regular;
  }

  &__sku-list {
    display: flex;
    flex-wrap: wrap;
    gap: $gap-sm;

    .sku-card {
      width: 100%;

      @media (min-width: 360px) {
        flex: 0 1 calc(50% - ($gap-sm / 2));
        width: auto;
      }
    }
  }

  &__sku-card {
    flex: 0 1 calc(50% - ($gap-sm / 2));
    height: 300px;
    background: $background;
    border-radius: $border-md;
  }
}