@import '../../../../styles/variables';

.scan-check-checkList {

  &__list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: 400px;
    padding-right: $gap-md;
    overflow-y: auto;
    overflow-x: hidden;

    .scan-check-helperBtn {
      min-height: 54px;
    }
  }
}