@import '../../styles/variables';

.skuInfo {
  position: relative;

  &__helper-alert {
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap-lg;
    margin-bottom: $gap-xxl;
    padding: $gap-lg + 4px $gap-xl $gap-lg;
    border-radius: $gap-lg + 4px;

    span {
      max-width: 350px;
      text-align: center;
      color: $slateGray;
      line-height: 1.14;
      font-size: $font-size-regular;
    }
  }

  &__sku-card {
    width: calc(50% - ($gap-sm / 2));
    min-width: 180px;
  }

  &__title {
    max-width: 260px;
    margin: $gap-xxl 0 $gap-lg $gap-sm;
    font-weight: 600;
    font-size: $font-size-regular;
  }

  &__pharmacy-list {
    display: flex;
    flex-direction: column;
    gap: $gap-sm - 2px;
  }
}