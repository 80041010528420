@import './variables';

@mixin universalHover($bgColor) {
  transition: background .2s ease-in-out;

  &:active {
    background-color: $bgColor !important;
  }

  @media (min-width: 1024px) {
    &:hover {
      background-color: $bgColor !important;
    }
  }
}

.hover-primary {
  @include universalHover($primary-hover);
}
.hover-secondary {
  @include universalHover($secondary-hover);
}
.hover-white {
  @include universalHover($white-hover);
}
.hover-olive {
  @include universalHover($olive-hover);
}
.hover-light-olive {
  @include universalHover($light-olive-hover);
}
.hover-light-brown {
  @include universalHover($light-brown-hover);
}
.hover-green {
  @include universalHover($green-hover);
}
.hover-light-yellow {
  @include universalHover($light-yellow-hover);
}
.hover-light-blue {
  @include universalHover($light-blue-hover);
}