@import '../../styles/variables';

.badge {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $lightBlue;
  border-radius: 50%;

  span {
    font-weight: 500;
    font-size: 32px;
  }
}