@import '../../styles/variables';

.popup {
  position: fixed;
  inset: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 768px;
  margin: 0 auto;
  background: $background;
  overflow-x: hidden;
  overflow-y: auto;
  
  &__container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__header {
    cursor: pointer;
    position: relative;
    max-width: fit-content;
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 40px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: $gap-lg;
      margin-top: 2px;
      background: #ffffff;
      border-radius: 50%;
    }

    .title {
      margin-left: $gap-sm;
      margin-top: 1px;
      color: $primary;
      font-weight: 700;
      font-size: 20px;
      line-height: 1.2;
    }
  }

  &__wrapper {
    flex: 1 1 auto;
    margin-top: $gap-lg;
    background: #ffffff;
    border-top-left-radius: $border-lg;
    border-top-right-radius: $border-lg;
  }

  .content {
    padding-top: $gap-xl;
    padding-bottom: $gap-xl;
    font-size: $font-size-regular;
  }
  
}